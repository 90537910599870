// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react";
import Client from "shopify-buy";
// Hooks
import useLocalStorage from "../hooks/useLocalStorage";
// Components
// ...

const EUR_KEY = 'b62dc4457ca62fd62299418de29bd17e'
// const OLD_ZAR_KEY = '591cf372eda6252579d805fb4f563ef8'
const ZAR_KEY = '71437ab18bff19159614fc96776d08f5'

export const shopifyClient = {
  'EUR': Client.buildClient({
    domain: 'thebemagugu.myshopify.com', // row.thebemagugu.com
    storefrontAccessToken: EUR_KEY
  }),
  'ZAR': Client.buildClient({
    domain: 'thebe-magugu-south-africa.myshopify.com', // sa.thebemagugu.com
    storefrontAccessToken: ZAR_KEY
  })
}

export const CartContext = React.createContext();
export const CartActionsContext = React.createContext();

export function useCartContext() {
	return React.useContext(CartContext);
}

export function useCartActions() {
	return React.useContext(CartActionsContext);
}

const CheckoutStore = ({ children }) => {

  // ----------------------------
  // Which currency?
  const [currency, setCurrency] = useLocalStorage('tm_currency_v3', 'ZAR'); // 'EUR' or 'ZAR'
	// const [currency, setCurrency] = useState('ZAR')
	// const currency = 'ZAR'

  // Exisiting cart?
  const [currentCheckout, setCurrentCheckout] = useState(null);
  const [checkoutId, setCheckoutId] = useLocalStorage('checkoutId');

  const createNewCheckout = () => {
    shopifyClient[currency].checkout.create().then((checkout) => {
      setCurrentCheckout(checkout);
      setCheckoutId(checkout.id);
    });
  }

  const fetchCheckout = useCallback(() => {

    if (!checkoutId) {
      createNewCheckout();
    } else if (!currentCheckout) {

      shopifyClient[currency].checkout.fetch(checkoutId)
        .then((checkout) => {
          // console.log(checkout)
          if (checkout && !checkout.completedAt) {
            setCurrentCheckout(checkout);
            setCheckoutId(checkout.id);
          } else {
            createNewCheckout();
          }
        })
        .catch((error) => {
          // console.log(error)
          createNewCheckout();
        });
    }
  }, [checkoutId, currentCheckout])

  // Intention here originally was to set the checkout on page enter
  useEffect(fetchCheckout, [checkoutId])

  // ----------------------------
  // When currency updates, reinitialize checkout

  const firstRender = useRef(true);
  const updateCheckoutCurrency = useCallback(() => {
    if (firstRender.current) {
      createNewCheckout();
    }
  }, [currency, createNewCheckout]);
  useEffect(updateCheckoutCurrency, [currency]);

	// ----------------------------
	// Fetch live products as a collection to reconcile collection pages
	const [liveProducts, setLiveProducts] = useState([])
	useEffect(() => {
		if (currency) {
			// Fetch all shopify products
			// 200 is the pageLimit, but there is a cap
			shopifyClient[currency].product.fetchAll(200).then((products) => {
				setLiveProducts(products)
			}).catch((error) => {
				console.log('fetch live products', error)
			});
		}
	}, [currency])

  // ----------------------------
  // Add item to cart

	const [didJustAddToCart, setDidJustAddToCart] = useState(null) // takes a variant id
	useEffect(() => {
		if (didJustAddToCart) {
			const timer = setTimeout(() => {
				setDidJustAddToCart(null)
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [didJustAddToCart])

  const addToCart = (lineItemsToAdd, callback) => {

    // Add an item to the checkout
    shopifyClient[currency].checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
      setCurrentCheckout(checkout);
			setDidJustAddToCart(lineItemsToAdd[0].variantId)
      !callback || callback()
    }).catch((error) => {
      console.log('addToCart', error)
    });
  }

  // ----------------------------
  // Update cart/checkout info
  const updateCartEmail = (email, callback) => {
    shopifyClient[currency].checkout.updateEmail(checkoutId, email).then((checkout) => {
      setCurrentCheckout(checkout);
      !callback || callback()
    }).catch((error) => {
      !callback || callback()
      console.error(error)
    });
  }
  

  // ----------------------------
  // Update quantity
  const updateLineItemQuantity = (variantId, quantity) => {

    // BUG: Why is this firing on refresh?

    const lineItemsToUpdate = [
      { id: variantId, quantity: quantity }
    ];

    // Update the line item on the checkout (change the quantity or variant)
    shopifyClient[currency].checkout.updateLineItems(checkoutId, lineItemsToUpdate).then((checkout) => {
      setCurrentCheckout(checkout);
    }).catch((error) => {
      // console.log('updateLineItemQuantity', error)
    });
  }

  // ----------------------------
  // Remove item
  const removeLineItem = (variantId) => {
    const lineItemIdsToRemove = [
      variantId
    ];

    // Remove an item from the checkout
    shopifyClient[currency].checkout.removeLineItems(checkoutId, lineItemIdsToRemove).then((checkout) => {
      setCurrentCheckout(checkout);
    }).catch((error) => {
      // console.log('removeLineItem', error)
    });
  }

  // ----------------------------
  // Toggle currency

  const changeCurrency = (currency) => {
		// return 'ZAR'
    setCurrency(prev => {
      return currency || (prev === 'EUR' ? 'ZAR' : 'EUR')
    })
  }

	// Should refactor into one context
  return (
    <CartContext.Provider value={{ 
			currentCheckout, 
			currency, 
			liveProducts,
			didJustAddToCart,
			addToCart, 
			updateLineItemQuantity, 
			removeLineItem, 
			changeCurrency,
      updateCartEmail
		}}>
      {children}
    </CartContext.Provider>
  )
}

// Other helper hooks

/*
	---------------------------
	Reconcile live products with contentful products

	- liveProducts: array of shopify products
	- contentfulProducts: array of contentful products
	- flags: array of flags to trigger re-render

	const contentfulProducts = [{
		shopifyZar: {
			handle: "the-be-magugu-1",
			variants: []
		},
		shopifyEur: {
			handle: "the-be-magugu-1",
			variants: []
		}
	}]
*/
export function useProductCollection(contentfulProducts=[], flags=[]) {

	const { liveProducts, currency } = useCartContext()
	const [products, setProducts] = useState([])

	useEffect(() => {
		if (liveProducts.length > 0) {
			const key = currency === 'ZAR' ? 'shopifyZar' : 'shopifyEur'
			const availableProducts = contentfulProducts.filter(p => p[key])
			// console.log('availableProducts', availableProducts)
			const resolvedProducts = availableProducts.map((product) => {
				const liveProduct = liveProducts.find((liveProduct) => {
					// console.log(liveProduct.handle === product[key].handle)
					return liveProduct.handle === product[key].handle
				})
				return {
					...product,
					shopifyProduct: liveProduct
				}
			}).filter(Boolean)
			
			setProducts(resolvedProducts)
		}
	}, [liveProducts, ...flags])

	return products
}
/* ------------------------ */

export default CheckoutStore

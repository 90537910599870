// const React = require("react")
// const App = require("./src/components/App").default

// exports.wrapPageElement = ({ element, props }) => {
//   // props provide same data to Layout as Page element will get
//   // including location, data, etc - you don't need to pass it
//   return <App {...props}>{element}</App>
// }

import React from "react"
import App from "./src/components/App"

export const wrapPageElement = ({ element, props }) => (
  <App {...props}>{element}</App>
)

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   prevRouterProps,
// }) => {
//   const { pathname } = location

//   // List of paths where you want to disable scroll restoration
//   const pathsWithoutScrollRestoration = [
//     "/products/heirloom-ii-custom-shirt",
//     "/products/heirloom-ii-custom-trousers",
//     "/products/heirloom-ii-custom-tote-bag",
//   ]

//   if (pathsWithoutScrollRestoration.includes(pathname)) {
//     // Disable automatic scroll restoration for specific pages
//     return false
//   }

//   // Use default scroll behavior for all other pages
//   return true
// }

// Dependencies
import React from "react"
// Hooks
// ...
// Components
import CheckoutStore from "../context/Checkout"

const App = ({ children }) => {

  return (
    <CheckoutStore>
      {children}
    </CheckoutStore>
  )
}

export default App

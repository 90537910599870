exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-faculty-press-js": () => import("./../../../src/pages/faculty-press.js" /* webpackChunkName: "component---src-pages-faculty-press-js" */),
  "component---src-pages-heirloom-i-js": () => import("./../../../src/pages/heirloom-i.js" /* webpackChunkName: "component---src-pages-heirloom-i-js" */),
  "component---src-pages-heirloom-ii-js": () => import("./../../../src/pages/heirloom-ii.js" /* webpackChunkName: "component---src-pages-heirloom-ii-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-templates-article-category-template-js": () => import("./../../../src/templates/article-category-template.js" /* webpackChunkName: "component---src-templates-article-category-template-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-collection-template-js": () => import("./../../../src/templates/collection-template.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-heirloom-ii-product-js": () => import("./../../../src/templates/heirloom-ii-product.js" /* webpackChunkName: "component---src-templates-heirloom-ii-product-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-shop-template-js": () => import("./../../../src/templates/shop-template.js" /* webpackChunkName: "component---src-templates-shop-template-js" */)
}

